import { translate, translateFormatter, type RichTextFormatter } from '@/domains/core/localization/translate';
export const {
  sponsorBrandInfoTitle
} = translate({
  "sponsorBrandInfoTitle": "Informazioni"
});
export const sponsorBrandInfoDescription = translateFormatter<{
  brand: string;
}>("Contenuti sponsorizzati dalla marca {brand}", undefined, undefined, "it");
export const {
  sponsorInfoTitle
} = translate({
  "sponsorInfoTitle": "Informazioni su un link sponsorizzato"
});
export const sponsorInfoModalDescription = translateFormatter("Prodotti sponsorizzati como parte di un servizio opzionale a pagamento sottoscritto dai Commercianti. <a>Per saperne di pi\xF9 sui prodotti sponsorizzati</a>. La visualizzazione di questo annuncio \xE8 basata sui termini di ricerca o sui prodotti da te consultati su questa pagina.", undefined, undefined, "it");
export const sponsorInfoDescription = translateFormatter("Prodotto sponsorizzato como parte di un servizio opzionale a pagamento sottoscritto dal Commerciante. <a>Per saperne di pi\xF9 sui rodotti sponsorizzati</a>. La visualizzazione di questo annuncio \xE8 basata sui termini di ricerca o sui prodotti da te consultati su questa pagina.", undefined, undefined, "it");
export const {
  knowMoreLink
} = translate({
  "knowMoreLink": "https://cdn.manomano.com/legal/consumer-information/it.pdf"
});
export const advertiserName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Nome dell\u2019 inserzionista:</strong> {name}", undefined, undefined, "it");
export const advertiserPayerName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Colui che ha pagato per questo annuncio pubblicitario:</strong> {name}", undefined, undefined, "it");