import { useCallback, type FunctionComponent, type KeyboardEvent, type MouseEvent } from 'react';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
const description = translateFormatter("Prodotto messo in evidenza nell'ambito di un servizio opzionale a pagamento sottoscritto dal Commerciante", undefined, undefined, "it");
export const {
  link
} = translate({
  "link": "https://cdn.manomano.com/legal/consumer-information/it.pdf"
});
export const SponsoredDescription: FunctionComponent = () => {
  const redirectLink = useCallback((event: KeyboardEvent<HTMLSpanElement> | MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    if (link) {
      window.location.href = link;
    }
    return null;
  }, []);
  return <>
      {description.format({
      a: (chunks: string) => <span key={chunks} role="link" tabIndex={0} onClick={redirectLink} onKeyPress={redirectLink}>
            {chunks}
          </span>
    })}
    </>;
};
SponsoredDescription.displayName = 'SponsoredDescription';